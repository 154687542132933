@import "../../scss/mixins/media-query";

.customer-profile {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    position: relative;


    .cards {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 0 6px 0;

        .card {
            padding: 10px;
            margin: 0;
            width: 100%;

            &.customer-info {
                width: 60%;
            }
            &.rental-info {
                width: calc(40% - 5px);
            }
            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @include md() {
            width: 100%;

            .card {
                margin: 0;
            }
        }
    }
}
