.page-size-select-wrapper {
    margin: 0 10px 0 0;
    min-width: 90px;

    .page-size-select {
        input[type="text"] {
            height: initial;
        }
    }
}
