@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.scooter-map {
    height: 100%;

    .spinner-wrapper {
        height: calc(100% - 54px);
    }

    .map-view {
        height: calc(100% - 104px);
    }
}
