@import "../../scss/mixins/media-query";

.login-page {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #3a3f52;
    height: 100%;
    width: 100%;
    z-index: 9998;
    @include sm() {
        justify-content: center;
    }

    .btn-google-login {
        height: 48px;
    }

    .fleet-title {
        max-width: 100%;
        margin: 240px 0 80px 0;
        @include sm() {
            margin-top: 0;
        }
    }
}
