.form-group-section {
    margin: 0 0 1.875rem 0;
    text-align: left;

    .group-title {
        text-align: left;
        font-size: 24px;
        margin-bottom: 16px;
        color: #4a4a4a;
    }
}
