@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.scooter-history {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0 10vw;

    &.not-exists {
        align-items: center;
        justify-content: center;
        height: calc(100vh - #{$main-desktop-header-tot-height});

        @include xs() {
            height: calc(100vh - #{$main-mobile-header-tot-height});
        }
    }

    .button-group {
        padding: 10px;
        width: 100%;
    }

    .scooter-profile {
        width: 100%;
    }

    .history-filter {
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc;
        padding: 10px 5px;
        margin: 0 0 50px 0;
        width: 100%;

        .form-row {
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        .form-group {
            margin: 0;
        }

        .btn {
            width: 100%;
        }
    }

    .list-wrapper {
        width: 100%;
        margin: 0;
    }

    .inactive {
        height: 1px;
        overflow: hidden;
        opacity: 0;
    }

    .spinner-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px auto;
        width: 100%;
    }

    .hide {
        display: none;
    }

    // override
    .form-control[readonly] {
        background-color: #fff;
    }

    .rdtPicker td.rdtActive {
        background-color: #39bbdc;
        text-shadow: none;
    }

    .rdtOpen .rdtPicker {
        width: 100%;
    }
}
