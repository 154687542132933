/**
 * Overwrite bootstrap forms
 */
@import "../setup/variable";
@import "../../scss/mixins/select";
@import "./reset-appearance";

@mixin form() {
    select.form-control {
        @include btn-select();
        background-image: url(../../assets/form-dropdown-arrow-focus.svg);
        background-repeat: no-repeat;
        background-position: calc(100% - 16px) center;
        padding: 0 30px 0 16px;
        border-radius: 20px;
        font-size: 16px;
        @include reset-appearance();

        option {
            padding: 3px;
        }

        &:focus {
            option:checked {
                background: $deep-sky-blue linear-gradient(0deg, $deep-sky-blue 0%, $deep-sky-blue 100%);
            }
        }

        &[multiple] {
            background-image: none;
            padding: 2px 16px;
        }
    }

    label {
        font-size: 0.875rem;
        font-weight: 600;
        color: $charcoal-grey;
    }

    input.form-control {
        padding: 12px;
    }

    .form-control {
        border: 2px solid $very-light-pink;
        border-radius: 0;
        font-size: 1rem;
        color: $secondary-font-color;

        &:focus {
            border-color: $charcoal-grey;
            color: $charcoal-grey;
            outline: none;
            box-shadow: none;
        }

        &:disabled {
            border: solid 2px $passive-dark;
            background-color: $passive-light;
        }
    }

    .form-check {
        padding-left: 1.75rem;
        margin: 0 0 8px 0;
    }

    .form-check-label {
        cursor: pointer;
        display: flex;
        flex-wrap: nowrap;
    }

    input[type="radio"] {
        @include reset-appearance();
        border: 2px solid $passive-dark;
        cursor: pointer;
        margin-left: 0;
        margin-right: 8px;
        margin-top: 0;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        position: relative;

        &:focus {
            outline: none;
        }

        &:checked {
            border: 2px solid $charcoal-grey;
            background: $charcoal-grey;
            box-shadow: 0 0 0px 4px $passive-light inset;
        }

        &:disabled {
            border-color: $passive-dark;
        }
    }

    input[type="checkbox"] {
        @include reset-appearance();
        background-size: 100% 100%;
        background: url(../../assets/checked.svg);
        background-position: top -100px left -100px;
        background-repeat: no-repeat;
        border: 2px solid $passive-dark;
        cursor: pointer;
        margin-left: 0;
        margin-right: 8px;
        margin-top: 0;
        min-width: 20px;
        width: 20px;
        height: 20px;
        object-fit: contain;
        position: relative;

        border-radius: 0;
        vertical-align: middle;

        &:focus {
            outline: none;
        }

        &:checked {
            background-position: top 0 left 0;
            border: none;
            box-shadow: none;
        }

        &:disabled {
            border-color: $passive-dark;
        }
    }

    input[type="radio"] + span, input[type="checkbox"] + span {
        height: 24px;
        font-size: 14px;
        font-weight: normal;
        line-height: 1.71;
        color: $charcoal-grey;
    }

    input[type="text"] {
        height: 40px;
    }

    .react-toggle {
        input[type="checkbox"] {
            display: none;
        }
    }
}
