@import "../../scss/mixins/media-query";
.scooter-profile-page {
    padding: 0 0 20px 0;

    .refresh {
        margin-left: auto;
    }

    .pagination {
        margin: 0 0 10px 0;
    }

    .rental-history-list {
        .list-view {
            min-height: 600px;
        }
    }
}
