@import "../../scss/setup/variable";

.search-field {
    border: 2px solid #d7d7d7;
    position: relative;
    box-sizing: border-box;
    overflow: hidden;

    input[name="search-text"].form-control {
        border: 0;
        padding-right: 32px;
        box-sizing: border-box;
    }

    .go-search {
        box-shadow: -2px 0 5px 0 rgba($very-light-pink, .5);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        background: none;
        margin: auto;
        border: none;
    }

    .control-group {
        margin: 0;
    }

    .caption,
    .error-message {
        display: none;
    }

    .control-group {
        width: 100%;
    }
}
