@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.main-header {
    background: $passive-light;
    height: $main-desktop-header-height;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px $view-desktop-side-padding;
    margin: 0 0 $main-header-bottom-margin 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9998;

    @include xs() {
        height: $main-mobile-header-height;
        padding: 5px $view-mobile-side-padding;
    }

    .btn.btn-menu {
        background: transparent;
        background-color: transparent;
        border: none;
        color: $passive-dark;
        font-size: 1.4rem;
        padding: 0 10px;
        margin: 0 0 0 -10px;
        height: 44px;
    }

    .logo {
        fill: #000;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        width: 160px;
        height: 40px;
    }

    .profile-sec {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .locale-select-form {
        margin: 0 5px 0 0;

        @include md() {
            display: none;
        }
    }
}
