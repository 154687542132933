.plate {
    .btn-link {
        font-size: .8rem;
        font-weight: normal;
        display: inline-block;
        line-height: unset;
        padding: 0;
        color: #007bff;
        text-decoration: none;
        width: 100%;
    }
}
