@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.googlemap {
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;

    #google-map {
        height: 100%;
    }

    .cluster img {
        height: 40px;
    }

    div[style^="z-index: 1000001;"] {
        &::before {
            content: "";
            height: 7px;
            display: block;
        }
    }

    // NOTCIE: force to overwrite style marker lobel (rewrite it if better solution have gotten)
    div[style^="position: absolute; left"] div[style^="display: table-cell"] {
        div {
            @include font-family-default();
            border-radius: 50%;
            color: #fff !important;
            line-height: 20px;
            transform: translate(15px, -15px);
            height: 20px;
            width: 20px;
            transition: transform 1s ease-out;
            background-repeat: no-repeat;
        }

        // 0 battery
        div[style^="color: rgb(0, 0, 0);"] {
            color: transparent;
            background-image: url(../../assets/iconGostationBubbleCharging.svg);
            background-size: 20px 20px;
        }

        // have batteries
        div[style^="color: rgb(255, 0, 0);"] {
            background-color: var(--green);
        }

        // selected with 0 battery
        div[style^="color: rgb(0, 0, 255);"] {
            color: transparent;
            background-image: url(../../assets/iconGostationBubbleCharging.svg);
            background-size: 20px 20px;
            transform: translate(25px, -25px);
        }

        // selected with batteries
        div[style^="color: rgb(255, 0, 255);"] {
            background-color: var(--green);
            transform: translate(25px, -25px);
        }
    }

    &.sticky {
        position: sticky;
    }

    .search-place-box {
        opacity: .9;
        border-radius: 0;
        transition: opacity .3s ease;

        &:focus {
            opacity: 1;
        }
    }

    .search-place-box {
        opacity: .9;
        border-radius: 0;
        transition: opacity .3s ease;

        &:focus {
            opacity: 1;
        }
    }

    .map-footer {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0 -2px 10px 0 rgba(0, 0, 0, .1);
        padding: 24px;
        text-align: center;
        width: 100%;
        position: sticky;
        bottom: 0;
        left: 0;
        right: 0;

        &:empty {
            padding: 0;
            height: 0;
        }

        .btn {
            margin: 0 16px 0 0;

            &:last-child {
                margin: 0;
            }
        }

        .vm-name {
            @include font-family-bold();
            font-size: 20px;
            color: var(--charcoal-grey);
            margin: 0 0 4px 0;
        }

        .vm-location {
            @include font-family-medium();
            font-size: 14px;
            color: var(--bluish-grey);
            margin: 0 0 16px 0;
        }
    }

    .current-location {
        border: 2px solid rgba(0, 105, 210, .2);
        width: 90px;
        height: 90px;
        background: rgba(0, 105, 210, .1);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: -1;

        &::after {
            content: "";
            background: #0069d2;
            border: 2px solid #fff;
            border-radius: 50%;
            position: absolute;
            transform: translateX(0);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 16px;
            width: 16px;
        }
    }

    .controlWrapper {
        background-color: #ffffff;
        border-radius: 2px;
        cursor: pointer;
        text-align: center;
        width: 40px;
        height: 81px;
        .zoom-in-btn {
            background-image: url(../../assets/zoomIn.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            width: 40px;
            height: 40px;
            &:hover {
                background-image: url(../../assets/zoomIn_hover.svg);
            }
        }
        .divider {
            position: relative;
            overflow: hidden;
            width: 30px;
            height: 1px;
            margin: 0px 5px;
            background-color: rgb(230, 230, 230);
            top: 0px;
        }
        .zoom-out-btn {
            background-image: url(../../assets/zoomOut.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 18px;
            width: 40px;
            height: 40px;
            &:hover {
                background-image: url(../../assets/zoomOut_hover.svg);
            }
        }
    }
}

.googlemap .gmap-marker {
    @include font-family-medium();
    background: $charcoal-grey;
    border: solid 1px #fff;
    border-radius: 4px;
    color: #eee;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    padding: 5px 8px;
    transition: all .3s ease;
    transform: translate( -50%, calc(-100% - 6px));
    position: absolute;
    white-space: nowrap;
    width: auto;

    &:after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translate(-50%, 0);
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 6px solid $charcoal-grey;
    }

    &.current-location {
        border: 2px solid rgba(0, 105, 210, .2);
        width: 90px;
        height: 90px;
        background: rgba(0, 105, 210, .1);
        border-radius: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
        z-index: -1;

        &::after {
            content: "";
            background: #0069d2;
            border: 2px solid #fff;
            border-radius: 50%;
            position: absolute;
            transform: translateX(0);
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 16px;
            width: 16px;
        }
    }

    &.light {
        background: #7c8c8f;
        box-shadow: 0 2px 6px 0px rgba(0, 0, 0, .3);
        color: #FFF;

        &:after {
            border-top-color: #7c8c8f;
        }
    }

    &.selected {
        background: $deep-sky-blue;
        color: $primary-text-color;
        z-index: 2;

        &:after {
            border-top: 6px solid $deep-sky-blue;
        }
    }

    &.model-b,
    &.model-f {
        &::before {
            @include font-family-bold();
            color: #fff;
            display: inline-block;
            font-size: 14px;
            margin: 0 9px 0 0;
        }

        &.selected {
            &::before {
                color: $primary-text-color;
            }
        }

        &.minimal {
            &::before {
                margin: 0;
            }

            .gmarker-caption {
                display: none;
            }
        }
    }

    &.model-b {
        &::before {
            content: "B";
            color: #00d7ff;
        }
    }

    &.model-f {
        &::before {
            content: "F";
            color: #ff7056;
        }
    }

    &.vm-station {
        background: none;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        border: 0;
        height: 32px;
        width: 32px;
        padding: 0;
        position: absolute;
        transition: none;

        &.hovered,
        &:hover {
            color: initial;
        }

        .battery-count {
            background: #0275ff;
            border-radius: 50%;
            color: #fff;
            display: block;
            line-height: 20px;
            height: 20px;
            width: 20px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(40%, -40%);
        }

        &.selected {
            background-size: 100%;
            height: 64px;
            width: 56px;

            &.hovered,
            &:hover {
                color: initial;
            }

            .battery-count {
                line-height: 28px;
                height: 28px;
                width: 28px;
            }
        }

        &.empty {
            .battery-count {
                background: #d7d7d7;
            }
        }

        &:after {
            display: none;
        }
    }
}
