@import "../../scss/mixins/media-query";

.welcome-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    z-index: 9998;
}
