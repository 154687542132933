@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.ticket-management {

    .refresh-button {
        z-index: 10;
        @include xs() {
            flex-direction: row;
        }
    }

    .toggle-text {
        margin-right: 5px;
    }

    .scooter-margin {
        margin-top: 24px;
    }

    .my-tickets {
        width: 70%;
        float: left;
        height: 90vh;
        @include md() {
            width: 100%;
            float: unset;
        }
        .list-view-control {
            &.hide-control {
                @include sm() {
                    display: none;
                }
            }
            @include md() {
                max-width: 100%;
                flex-wrap: wrap;
            }
        }
    }

    .flex-group {
        @include md() {
            flex-direction: row;
        }
    }

    .detail-list {
        width: 70%;
        height: 90vh;
        float: left;
        @include md() {
            width: 100%;
            float: unset;
        }
    }

    .scooter-list-table {
        width: calc(100vw - 80px);
        height: calc(100% - 124px);
        @include md() {
            width: 100%;
        }
        .list-view-wrap {
            height: 100%;
            width: calc(100% - 10px);
            margin: 0;
        }
    }

    .ticket-form {
        display:block;
    }

    input[name="search-text"].form-control {
        @include xs() {
            max-width: 200px;
        }
    }

    .scooter-details {
        width: 100%;
        display: inline-grid;
        float: left;
        padding-right: 24px;
        @include md() {
            padding-right: 0px;
        }
        .card {
            padding: 23px 24px;
            @include md() {
                width: 100%;
            }
            .card-body {
                padding: 0;
            }
        }
    }

    &.map-mode {
        .map-view {
            height: 710px;
            visibility: visible;
            width: 100%;
            @include xs() {
                height: 65vh;
            }
        }
        .scooter-map-view {
            height: 420px;
            @include md() {
                height: calc(60vh - 100px);
                margin-bottom: 40px;
            }
        }
        .scooter-list-table {
            width: 100%;
            height: calc(100% - 124px);
        }
        @include md() {
            .my-tickets {
                visibility: hidden;
                height: unset;
            }
        }
    }
}

.web-button {
    @include md() {
        display: none;
    }
}

.mobile-button {
    display: none;
    @include md() {
        display: block;
        margin-right: 8px;
    }
}

.map-control {
    @include md() {
        position: absolute;
        right: 40px;
        top: 165px;
        z-index: 0;
    }
    @include sm() {
        top: 155px;
    }
    &.my-task-map-control {
        top: 140px;
        @include xs() {
            top: 120px;
        }
    }
    &.ticket-detail-map-control {
        @include sm() {
            top: 200px;
        }
        @include xs() {
            top: 180px;
        }
    }
    &.special-map-control {
        top: 100px;
    }
}

.map-toggle {
    background: $white;
    min-width: 36px;
    width: 36px;
    height: 36px;
    margin: 0;
    border-radius: 50%;
    display: none;
    padding: 0;
}

@include md() {
    .map-toggle {
        display: block;
    }
}

.map-footer {
    text-align: center;

    .plate {
        font-size: 20px;
        margin: 0 0 5px 0;
    }

    .icon-battery {
        display: flex;
        align-items: center;

        &::before {
            content: "";
            display: inline-block;
            background-image: url("../../assets/icon-battery.svg");
            background-repeat: no-repeat;
            background-position: left center;
            height: 20px;
            width: 20px;
        }
        &::after {
            content: "%";
            padding: 0 5px 0 0;
        }
    }

    .profile-battery {
        margin: 0 0 6px 0;
        flex-direction: row;
        justify-content: center;
    }

    .profile-location {
        margin: 0 0 16px 0;
    }
}
