@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.scooter-list {
    height: 100%;

    .list-row .cell-tag_description .cell-content {
        align-items: flex-start;
    }

    .btn-do-action {
        margin: 0 8px 0 0;
    }

    .btn-new-ticket {
        margin: 0 8px 0 0;
    }

    .search-field {
        margin: 0 10px 0 0;

        @include sm() {
            margin-bottom: 10px;
            width: 100%;
        }
    }

    .filters {
        display: flex;
        flex-wrap: wrap;

        @include sm() {
            justify-content: flex-start;
        }

        .custom-filter {
            margin: 0 5px 0 0;

            @include sm() {
                margin: 0 5px 5px 0;

                .btn-filter {
                    font-size: 14px;
                }
            }
        }
    }

    .scooter-list-filter {
        display: flex;
        justify-content: flex-start;
        margin: 0 0 15px 0;

        .search-text {
            width: 30%;
            min-width: 150px;
        }
        .btn {
            margin: 0 10px 0 0;
            width: auto;
        }
    }
    .pagination-control-sec {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: nowrap;

        ul {
            margin-bottom: 0;
        }
        .tot-selection {
            margin: 0 10px 0 0;
        }
        @include md() {
            flex-wrap: wrap;
            .page-size-select {
                display: none;
            }
        }
    }
    .scooter-list-control-sec {
        .list-view-control {
            margin-right: 10px;
        }
    }

    .scooter-list-table {
        display: flex;
        flex-direction: row;
        height: calc(100% - 100px);

        @include md() {
            display: block;
            flex-direction: column;
            padding: 0;
        }

        .list-view-wrap {
            overflow: auto;
            width: 100%;
            transition: all .3s ease-out;

            @include md() {
                width: 100%;
                height: 100%;
                max-height: calc(100vh  - #{$main-mobile-header-tot-height});
                overflow: scroll;
            }
        }

        .cell-checkbox {
            padding: 0;

            label {
                cursor: pointer;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                height: 100%;
                width: 38px;
            }

            input[type="checkbox"] {
                cursor: pointer;
            }
        }
    }

    .scooter-cell {
        position: relative;
        max-width: 100%;
        padding: 0 20px 0 0;
        margin: auto;
        .scooter-cell-display {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
