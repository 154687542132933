@import "../../scss/setup/variable";
@import "../../scss/mixins/badge";
@import "../../scss/mixins/button";
@import "../../scss/mixins/form";
@import "../../scss/mixins/media-query";

.app {
    text-align: center;
    background-color: $white;
    display: flex;
    align-items: center;
    flex-direction: row;
    height: 100%;

    .main-content {
        width: 100vw;
        height: 100%;
    }

    .app-container {
        padding: $main-desktop-header-tot-height $view-desktop-side-padding 20px $view-desktop-side-padding;
        height: 100%;

        @include xs() {
            padding: $main-mobile-header-tot-height $view-mobile-side-padding 20px $view-mobile-side-padding;
        }
    }

    .icon {
        margin: 0 5px 0 0;
    }

    @include badge();
    @include button();
    @include form();
}
