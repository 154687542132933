@import "../../scss/mixins/media-query";

.button-group {
    display: flex;

    &.start {
        justify-content: flex-start;
    }

    &.end {
        justify-content: flex-end;
    }

    .btn {
        margin: 0 12px 0 0;
    }

    .btn:last-child {
        margin: 0;
    }

    @include md() {
        justify-content: center;
    }
}
