@import "../../scss/setup/variable";

.copy-to-clip-board {
    position: relative;
    max-width: 100%;
    padding: 0 20px 0 0;
    margin: auto;

    .copy-to-clip-board-display {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .fa-copy {
            visibility: hidden;
            position: absolute;
            top: 0;
            right: 2px;
            margin: auto;
            padding: 3px 0;
            width: 18px;
            border-radius: 3px;
            color: $charcoal-grey;
            background: $very-light-pink;
            transition: .5s color;
            box-shadow: rgba($charcoal-grey, .3) 1px 2px 2px;
            transform: translateY(-5px);

            &:hover {
                color: $charcoal-grey;
                filter: invert(1);
                background: rgba($very-light-pink, .9);
                cursor: pointer;
            }
        }
    }

    .copy-to-clip-board-hidden-input {
        position: absolute;
        left: -9999px;
    }

    &:hover {
        .copy-to-clip-board-display {
            .fa-copy {
                visibility: visible;
            }
        }
    }
}
