.button {
    position: relative;
    overflow: hidden;
    &.loading:disabled {
        background-color: rgba(0, 0, 0, .5);
        pointer-events: none;
        .lds-ring {
            position: absolute;
            height: 32px;
            width: 32px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
        }
    }
}
