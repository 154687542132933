@import "../../scss/mixins/media-query";
@import "../../scss/setup/variable";

.attachment-container {
    .attachment {
        cursor: pointer;
        height: 60px;
        border-radius: 4px;
        transition: box-shadow 0.15s ease-in-out;
    
        &:hover {
            box-shadow: 0 0 0 0.2rem rgba(199, 199, 199, 0.5);
        }
    }
    
    .preview-modal {
        .modal-box {
            @include md() {
                height: 100%;
            }
        }

        .modal-body {
            height: 484px;
            overflow: auto;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    
        .preview {
            display: block;
            margin: 0 auto;
            max-width: 100%;
            max-height: 100%;
        }
    }
}