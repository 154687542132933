@import "../../scss/mixins/media-query";

.history-list {
    list-style: none;
    margin: 0 auto;
    padding-left: 0;

    @include sm() {
        width: 100%;
    }

    .list-item {
        display: flex;
        margin: 0 0 10px 0;
        width: 100%;

        .cell {
            flex: 1;
            align-self: center;
        }

        .location {
            display: inline-block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .details {
            border: 1px solid #fff;
            border-radius: 5px;
            flex: 4;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0 10px;
            position: relative;
            max-width: calc(100% - 320px);

            &::before {
                content: "";
                background: #eee;
                display: block;
                border: 1px solid #fff;
                border-right: none;
                border-bottom: none;
                transform: rotateZ(-45deg);
                height: 15px;
                width: 15px;
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
                left: -8px;
            }
        }

        .updated-time {
            padding: 0 15px 0 0;
            text-align: right;
            flex: initial;
            width: 180px;
        }

        .event-type {
            text-align: left;
            font-size: .725rem;
            line-height: 2rem;
            padding-left: 40px;
            position: relative;
            width: 140px;
            flex: initial;

            &::before,
            &::after {
                content: "";
                background-image: linear-gradient(to right, #000 0%, #000 5px, transparent 6px);
                background-size: 5px 100%;
                background-position: center top;
                background-repeat: no-repeat;
                box-sizing: border-box;
                width: 25px;
                height: 25px;
                position: absolute;
                left: 0;
            }

            &::before {
                z-index: 1;
            }

            &::after {
                height: 50px;
                top: 10px;
            }

            &::first-letter {
                color: #000;
                font-size: 1.5em;
                position: absolute;
            }
        }

        // event types
        .event-3 {
            color: transparent;

            &::first-letter {
                color: #fff;
                background: #0163AD;
                border: 1px solid #0163AD;
                border-radius: 5px;
                padding: 0 5px;
                margin-left: -5px;
            }
        }

        .event-2 {
            color: transparent;

            &::first-letter {
                color: transparent;
            }
        }

        .event-0,
        .event-5,
        .event--1 {
            font-size: 1rem;
            position: relative;

            &:before {
                background: #fff;
                border: 2px solid #000;
                border-radius: 50%;
                position: absolute;
                margin: auto;
                top: 0;
                bottom: 0;
            }
        }

        .event--1 {
            &::before {
                background: #000;
                border: 2px solid #000;
                border-radius: 50%;
                box-shadow: 0 0 0px 4px #fff inset;
            }

            &::after {
                background: none;
            }
        }

        .event-0 {
            &::before {
                border: 2px dashed #000;
            }
        }
    }
}
