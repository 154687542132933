@import "./scss/setup/variable";
@import "./scss/mixins/media-query";
@import "./scss/mixins/font-family";

// root variables
:root {
    --deep-sky-blue: #{$deep-sky-blue};
    --bluish-grey: #{$bluish-grey};
    --bright-turquoise: #{$bright-turquoise};
    --black: #{$black};
    --charcoal-grey: #{$charcoal-grey};
    --very-light-pink: #{$very-light-pink};
    --shamrock: #{$shamrock};
    --strawberry: #{$strawberry};
    --green: #{$battery-green};

}

html, body, #root {
    @include font-family-default();
}

html, body {
    height: 100%;
    min-height: 100%;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-color: $white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: $desktop-font-size;
    color: $charcoal-grey;
    scroll-behavior: smooth;

    pre {
        @include font-family-default();
    }

    @include md() {
        font-size: $mobile-font-size;
    }
}

#root {
    height: 100%;
}

@font-face {
    font-family: "TTNormsPro-Bold";
    src: url("./assets/fonts/TT-Norms-Pro-Bold.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-Bold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "TTNormsPro-ExtraBold";
    src: url("./assets/fonts/TT-Norms-Pro-ExtraBold.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-ExtraBold.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "TTNormsPro-Medium";
    src: url("./assets/fonts/TT-Norms-Pro-Medium.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-Medium.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}

@font-face {
    font-family: "TTNormsPro-Regular";
    src: url("./assets/fonts/TT-Norms-Pro-Regular.woff") format("woff"),
        url("./assets/fonts/TT-Norms-Pro-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal
}
