@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";

.btn-remote-control-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 4px;
    margin: 12px;
    overflow: auto;

    @include sm() {
        width: 44%;
        margin: 6px;
    }

    .btn.btn-remote-control {
        position: relative;
        border: none;
        background-position: center center;
        background-size: 80px;
        background-repeat: no-repeat;
        background-color: rgba($very-light-pink, .25);
        border-radius: 4px;
        height: 140px;
        width: 140px;
        max-width: 100%;
        transition: opacity .3s ease-in-out, color .3s ease-in-out, border .3s ease-in-out, background-color .3s ease-in-out;

        &.rpc-lock {
            background-image: url(../../assets/icon-lock.svg);
        }

        &.rpc-unlock {
            background-image: url(../../assets/icon-unlock.svg);
        }

        &.rpc-opencart {
            background-image: url(../../assets/icon-seat-open.svg);
        }

        &.rpc-search-location {
            background-image: url(../../assets/icon-ring.svg);
        }

        &:hover {
            background-size: 90%;
            background-repeat: no-repeat;
            background-color: #5a6268;
            color: #FFF;
            border: 0;
        }

        &.calling {
            .btn-title {
                visibility: hidden;
            }
    
            .lds-ring {
                display: block;
            }
        }
    
        .icon {
            margin: 0 5px 0 0;
        }

        .lds-ring {
            display: none;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
        }
    }

    .btn-title {
        height: 24px;
        font-size: 14px;
        line-height: 1.71;
        color: $charcoal-grey;
        @include font-family-bold;
        margin-top: 8px;
    }
}
