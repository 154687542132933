@import "../../scss/setup/variable";

.collapse-banner {
    margin: 0 0 10px 0;
    text-align: left;

    .summary {
        background-color: rgba($very-light-pink, .3);
        color: $charcoal-grey;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        width: 100%;
        font-size: 24px;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.17;
        letter-spacing: normal;
        padding: 24px;
    }

    .collapse-icon {
        background-repeat: no-repeat;
        background-position: center center;
        width: 16px;

        &.open {
            background-image: url(../../assets/icon-accordion-close.svg);
        }

        &.close {
            background-image: url(../../assets/icon-accordion-open.svg);
        }
    }

    .collapse,
    .collapsing {
        width: 100%;

        .card {
            border: 1px solid rgba($very-light-pink, .3);
            border-top: 0;
            margin: 0;
            overflow: hidden;
        }
    }

    &.loading {
        .summary:hover,
        .collapse:hover {
            cursor: progress;
        }
    }
}
