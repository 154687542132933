@import "../../scss/setup/variable";

.lang-buttons {
    margin-bottom: 40px;

    .lang-button {
        position: relative;
    }

    @at-root {
        .account-profile .panel-body {
            .lang-button {
                font-size: 16px;
                color: #3a3f52;
                background-color: initial;
                border: 0;
                padding: 0;
                height: initial;

                &:active.btn, &.active.btn {
                    color: var(--deep-sky-blue);
                    background-color: initial;
                    border-color: initial;
                    border: 0;
                }

                &.active.btn:focus {
                    box-shadow: none;
                }

                &.btn-secondary:not(:disabled):not(.disabled):active:focus {
                    box-shadow: none;
                }
            }
        }
    }
}
