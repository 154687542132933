@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";
@import "../../scss/mixins/font-family";
@import "../../scss/mixins/util";

.list-view {
    height: 100%;
    width: 100%;
    overflow: auto;

    .table {
        margin-bottom: 0;
    }

    .cell-content {
        @include dotdotdot();
        white-space: pre-wrap;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px 0;
    }

    .header {
        background-color: rgba(#d7d7d7, .5);
        border-bottom: 0;
        color: var(--charcoal-grey);
        font-size: 1rem;
        font-weight: bold;
        font-stretch: condensed;

        .cell {
            @include font-family-medium();
            border-right: 2px solid #fff;
            font-weight: 500;
        }
    }

    .list-row {
        &.row-odd {
            background: rgba(#d7d7d7, .05);
        }

        &.row-even {
            background: rgba($alternative-text-light-color, .25);
        }

        &:hover {
            background: rgba(#06f3f9, .1);
        }
    }

    .cell {
        border: 0;
        border-right: 2px solid #fff;
        display: flex;
        line-height: 1.14;
        font-size: 14px;
        justify-content: center;
        align-items: center;
        height: 100%;
        min-width: 40px;
        padding: 0 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.cell-checkbox {
            cursor: pointer;
            padding: 0;
            min-width: 40px;
            width: 40px;

            .cell-content {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            input {
                position: relative;
                margin: 0;
            }
        }

        &:empty::after {
            content: '--';
        }

        &:last-child {
            border-right: 0;
        }
    }

    .sortable {
        cursor: pointer;

        .sorting {
            background-image: url(../../assets/icon-sorting-inactive.svg);
            background-repeat: no-repeat;
            background-position: center center;
            background-size: 100%;
            display: block;
            height: 16px;
            width: 16px;

            &.asc {
                background-image: url(../../assets/icon-sorting-ascending.svg);
            }

            &.desc {
                background-image: url(../../assets/icon-sorting-descending.svg);
            }
        }
    }
}
