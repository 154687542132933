@import "../../scss/mixins/media-query";

.card {
    height: unset;
    padding: 10px;
    margin: 0 10px 6px 0;
    width: 100%;

    &:nth-child(even) {
        margin-right: 0;
    }
}

.profile-line {
    display: flex;
    margin: 0 0 1rem 0;

    .profile-caption {
        text-align: right;
        margin: 0 5px 0 0;
        flex: 1;
    }

    .profile-item {
        flex: 1;
        display: inline-block;
        text-align: left;
        word-break: break-all;
    }
}
