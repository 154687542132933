.popup-error-message {
    .modal-box {
        text-align: right;
    }

    .error {
        color: #ce0000;
        font-size: 2.25rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .icon {
        font-size: 3.25rem;
    }

    .left-btn {
        margin-right: 10px;
    }
}
