@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";
@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";

$avatar-size: 36px !default;
$large-avatar-size: 67px !default;

.account-profile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    padding: 2px;
    margin: 0;
    outline: none;
    word-break: keep-all;
    box-sizing: border-box;
    position: relative;

    .collasible-panel {
        background: rgba(255, 255, 255, 0);
        visibility: hidden;
        transition: visibility 0s, background .5s ease-out;

        &.show {
            background: rgba(0, 0, 0, .1);
            visibility: visible;

            .panel-body {
                opacity: 1;
            }
        }
    }

    .panel-body {
        background: #FFF;
        border-radius: 20px;
        padding: 32px;
        position: absolute;
        right: 16px;
        top: calc(#{$main-desktop-header-height} + 16px);
        min-height: 333px;
        min-width: 312px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        transition: all .2s ease-out;
        opacity: 0;
        z-index: -1;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include xs() {
            top: calc(#{$main-mobile-header-height} + 16px);
        }
    }

    figcaption {
        margin-bottom: 32px;
    }



    .info {
        margin: 0;
        color: #3a3f52;
    }

    .name {
        @include font-family-bold;
        line-height: 23px;
        font-size: 20px;
        margin-bottom: 8px;
    }

    .email {
        line-height: 16px;
        font-size: 14px;
    }

    .avatar {
        border-radius: 50%;
        height: $avatar-size;
        width: $avatar-size;
        cursor: pointer;

        &.large {
            height: $large-avatar-size;
            width: $large-avatar-size;
            margin-bottom: 16px;
        }
    }
}
