@import "../../scss/setup/variable";

.choice-type {
    margin: 0 5px 0 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .choice-for-caption {
        margin: 0 5px 0 0;
    }

    .form-check-input {
        margin: 0;
        position: static;
        display: none;

        & + .choice-type-text {
            height: initial;
        }
    }

    .choice-type-text {
        border-radius: 15px;
        border: 1px solid #ccc;
        cursor: pointer;
        padding: 3px 10px;
        transition: border-color .3s ease-in;
    }

    .form-check-input:checked + .choice-type-text {
        border-color: $primary-light;
    }
}

.choice-group-field {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    & > * {
        margin-right: 5px;
    }
}
