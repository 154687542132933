.remove-user {
    .message {
        text-align: left;
        margin-bottom: 32px;
        overflow: auto;
        max-height: 400px;
    }

    .modal-box {
        max-width: 800px;
    }
}