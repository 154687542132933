@import "../../scss/setup/variable";
@import "../../scss/mixins/media-query";

.scooter-search {
    margin: 0 auto;

    .filter {
        display: flex;
        justify-content: center;
        padding: 0 15px 15px;
        @include md() {
            padding: 0 0 15px 0;
        }

        .search-text {
            width: 30%;
            min-width: 150px;
            margin: 0 10px 0 0;
            @include md() {
                width: 100%;
            }
        }

        .btn {
            word-break: keep-all;
        }
    }
}
