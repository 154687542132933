@import "../../../scss/mixins/media-query";
@import "../../../scss/setup/variable";
@import "../../../scss/mixins/font-family";

.scooter-profile-page {
    padding: 0 0 20px 0;
    .navigation-title {
        @include sm() {
            font-size: 22px;
        }
    }
    .info-wrapper {
        display: flex;
        flex-wrap: wrap;
        font-size: 1rem;
        justify-content: space-between;
        align-items: flex-start;
        width: 100%;
        margin: 0 0 10px 0;
        position: relative;

        .card {
            width: calc(70% - 10px);
            margin: 0;
            height: 100%;
            padding: 10px;
            border: none;
            > h2 {
                @include font-family-default();
                line-height: 1.33;
                font-size: 24px;
                margin-bottom: 16px;
                color: #4a4a4a ;
                font-weight: 500;
            }
            &:nth-child(even) {
                margin-right: 0;
            }
        }

        @include md() {
            width: 100%;
            flex-direction: column;
            margin-top: 20px;
            .card {
                margin: 0 0 10px 0;
                width: 100%;
            }
        }

        @include xs() {
            margin-top: 20px;
        }
    }

    .navigation-bar {
        width: calc(100vw - 80px);
        @include md() {
            width: 100%;
        }
    }

    .pagination {
        margin: 0 0 10px 0;
    }

    .rental-history-list {
        .list-view {
            min-height: 600px;
        }
        .card {
            width: 100% !important;
        }
    }

    .title {
        text-align: left;
    }

    .detail-info {
        display: grid;
        grid-template-columns: repeat(2, 50%);
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: $charcoal-grey;
        .profile-line {
            width: 70%;
            @include md() {
                width: 100%;
            }
        }
        .profile-caption {
            font-size: 14px;
            font-weight: 600;
            line-height: 1.71;
            @include sm() {
                font-size: 16px;
            }
        }
        @include sm() {
            display: block;
            font-size: 16px;
        }
    }

    .profile-line {
        width: 100%;
        @include sm() {
            width: 100%;
        }
        .profile-caption {
            text-align: left;
            margin: 0 5px 0 0;
            flex: 1;
        }

        .profile-item {
            flex: 1;
            display: flex;
            text-align: left;
            word-break: break-all;
            align-items: center;

            &:empty::before {
                content: "--";
            }
        }
    }
    .button-group {
        padding: 91px 10px 10px;
        width: 100%;
        @include md() {
            padding: 40px 10px 10px;
        }
    }
}
