@import "../../scss/mixins/media-query";
@import "../../scss/mixins/util";
@import "../../scss/setup/variable";
@import "../../scss/mixins/font-family";

$start-color: #00D958;
$end-color: #5FBAD9;
$menu-color: #3a3f52;

.sidebar {
    background: rgba(0, 0, 0, .1);
    transition: background .5s ease-out;

    aside {
        background: #3a3f52;
        min-width: 360px;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 9998;
        transition: all .3s ease-out;
        transform-origin: left;
        transform: translateX(0);
        overflow: auto;

        @include md() {
            width: 100vw;
        }
    }

    &.hide {
        background: rgba(255, 255, 255, 0);
        width: 0;

        aside {
            transform: translateX(-100%);
        }

        .btn-close-menu {
            display: none;
        }
    }

    .header {
        @include font-family-bold;
        top: 0;
        font-size: 24px;
        color: #FFF;
        text-align: left;
        padding: 26px 40px;
    }

    menu {
        height: calc( 100% - 88px );
        overflow: auto;
        padding: 0 0 96px 0;
        margin: 0;
    }

    .menu {
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;

        &.is-closed {
            display: none;
        }

        .menu-category {
            border-bottom: 1px solid $menu-color;
        }

        .menu-category,
        .menu-item {
            color: #CCC;
        }

        .category, .menu-link {
            padding: 0 24px 0 40px;
        }

        .category {
            @include font-family-medium;
            margin: 0;
            background-color: rgba(0, 0, 0, .2);
            font-size: 18px;
            line-height: 60px;
            color: #FFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            &.is-closed {
                .category-link {
                    opacity: .5;
                }
            }

            .collapse-icon {
                background-repeat: no-repeat;
                background-position: center center;
                width: 15px;
                height: 16px;
                opacity: 1;

                &.open {
                    background-image: url(../../assets/nav-collapse-open.svg);
                }

                &.close {
                    background-image: url(../../assets/nav-collapse-close.svg);
                }
            }
        }

        .menu-item {
            line-height: 60px;
            &:hover {
                background-color: rgba(255, 255, 255, .1);

                .menu-link {
                    color: #01ffda;
                }
            }
        }

        .menu-link {
            font-size: 18px;
            color: #CCC;
            text-decoration: none;
            display: block;
        }

        .active {
            color: #FFF;
            font-weight: 500;
        }
    }

    .version {
        @include font-family-medium;
        color: #FFF;
        font-size: 14px;
        width: 100%;
        margin: auto;
        background-color: $menu-color;
        line-height: 96px;
        height: 96px;
        opacity: .9;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
    }
}
