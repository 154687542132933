@import "../../scss/mixins/media-query";

.flex-group {
    display: flex;
    margin: 0 0 10px 0;
    justify-content: space-between;
    align-items: center;

    @include xs() {
        flex-direction: column;
    }

    &.row {
        @include md() {
            flex-direction: row;
        }
    }

    & + .flex-group {
        @include md() {
            margin: 0 0 10px 0;
        }
    }

    .flex-group,
    &:last-child {
        margin: 0;

        @include md() {
            margin: 0 0 10px 0;
        }
    }

    .flex-group,
    &:last-child {
        margin: 0;
    }

    &.start {
        justify-content: flex-start;
    }

    &.end {
        justify-content: flex-end;
    }

    &.middle {
        justify-content: center;
    }

    &.center {
        align-items: center;
    }

    &.space-between {
        justify-content: space-between;
    }

    &.align-start {
        align-items: flex-start;
    }

    &.align-stretch {
        align-items: stretch;
    }

    &.align-center {
        align-items: center;
    }

    &.align-end {
        align-items: flex-end;
    }

    &.gap {
        & > *,
        & > .flex-child {
            margin: 0 8px 0 0;
        }

        & > *:last-child,
        & > .flex-child:last-child {
            margin: 0;
        }
    }
}
