@import "../../../scss/mixins/media-query";
@import "../../../scss/setup/variable";

.ticket-system-map {
    width: 30%;
    display: inline-grid;
    @include md() {
        width: 100%;
        display: block;
    }
    .map-view {
        visibility: hidden;
    }
}
