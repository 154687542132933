$start-color: #00D958 !default;
$end-color: #5FBAD9 !default;

.battery-capacity {
    &.visualization {
        background-image: linear-gradient(to left, $start-color 0%, $end-color 100%);
        background-repeat: no-repeat;
        text-align: center;
        display: flex;
        align-items: center;

        &::before {
            content: "";
            display: inline-block;
            width: 5px;
        }
    }

    &.has-percentage::after {
        content: "%";
        display: inline-block;
    }
}
