@import "../../scss/mixins/media-query";
@import "../../scss/mixins/select";
@import "../../scss/setup/variable";

.custom-filter {
    position: relative;
    display: inline-block;
    margin: 0 5px;

    .button-group {
        margin: 0;
    }

    .error {
        color: $strawberry;
        margin: 0 8px 0 0;
        font-size: 12px;
    }

    .filter-display-text {
        margin: 0 16px 0 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 300px;

    }

    .blocker {
        background: transparent;
        z-index: 9;
        &.hide {
            display: none;
        }
    }

    .card {
        padding: 24px;
        margin: 0;
    }

    .card-body {
        padding: 0;
    }

    .filter-input {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.fas::before {
            float: right;
            padding: 12px 48px 12px 16px;
        }
    }

    .btn.btn-filter {
        @include btn-select();
        display: flex;
        align-items: center;

        .arrow {
            margin: 0;
        }

        &.applied {
            background: #000;
            border-color: #000;
            color: #FFF;
        }

        .fas.fa-times {
            margin-left: 12px;
        }

        .fas.fa-times {
            margin-left: 12px;
        }

        .fas.fa-times:hover {
            opacity: .5;
        }
    }

    .collasible-panel {
        border-radius: 20px;
        box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.1);
        position: absolute;
        min-width: 300px;
        transform: translateY(7px);
        overflow: hidden;
        z-index: 9997;
        visibility: hidden;
        transition: all .2s ease-out;

        &.show {
            visibility: visible;
        }

        .card {
            border: 0;
        }
    }

    .content {
        display: flex;
        flex-direction: column;

        .search-field {
            margin: 0 0 10px 0;
        }
    }

    .filter-option {
        display: flex;
    }

    .btn-group {
        padding: 0;
        display: block;
        text-align: right;
        margin: 0;
    }

    .form-control.is-invalid {
        background-image: none;
    }
}

.multiselect-filter {
    .option-container {
        max-height: 172px;
        margin: 0 0 10px 0;
        overflow: auto;
    }

    .filter-option {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:last-child {
            margin: 0;
        }
    }

    .btn-group {
        text-align: left;
    }
}

.number-range-filter {
    .form-group {
        display: flex;
        justify-content: space-between;
        margin-bottom: 0;

        .form-control {
            width: initial;
        }

        .max-props {
            width: 195px;
        }
    }

    .form.inline-form {
        .caption {
            width: 100px;
        }

        .control-wrapper {
            width: calc(100% - 100px);
        }
    }
}

.datetime-range-filter {
    .form-group {
        &:first-child {
            margin: 0 24px 0 0;
        }
    }

    .datetime-box {
        line-height: 36px;
        width: 100%;
        padding: 0 12px;
        box-sizing: border-box;
    }

    .form-groups {
        display: flex;
        justify-content: space-between;

        @include md() {
            flex-direction: column;
        }

        .datetime-picker {
            width: 250px;
            margin: auto;

            .rdtPicker {
                position: relative;
            }
            .input-wrap {
                position: relative;

                .btn-reset {
                    position: absolute;
                    border: none;
                    right: 5px;
                    top: 0;
                    transform: translateY(50%);
                }
            }

            .form-control {
                display: none;
            }
        }
    }
    .btn-group {
        float: right;
    }
}

.corpText {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 180px;
}
